@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

/* stylelint-disable max-line-length */

@function iconNew( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
        '   <title>new</title>' +
        '   <g fill="#{ $color }">' +
        '       <path d="M3,8.4H3A2.3,2.3,0,0,0,.5,11v4.6H1.6V11.3c0-1.1.5-1.7,1.4-1.8s1.4.7,1.4,1.8v4.3H5.5V11A2.3,2.3,0,0,0,3,8.4Z"/>' +
        '       <path d="M10.3,8.4C8.4,8.4,7.2,9.8,7.2,12s1.2,3.6,3.2,3.6a2.8,2.8,0,0,0,3-1.9H12.2a1.8,1.8,0,0,1-1.8,1c-1.2,0-2-.9-2-2.3h5.1v-.4C13.4,9.7,12.2,8.4,10.3,8.4Zm-2,3.1a2,2,0,0,1,2-2.1,1.9,1.9,0,0,1,1.9,2.1Z"/>' +
        '       <polygon points="22.9 8.5 21.5 14.1 21.4 14.1 19.8 8.5 18.7 8.5 17.2 14.1 17.1 14.1 15.7 8.5 14.5 8.5 16.5 15.5 17.6 15.5 19.2 10.1 19.3 10.1 20.9 15.5 22 15.5 24 8.5 22.9 8.5"/>' +
        '   </g>' +
        '</svg>'
    );
}

/* stylelint-enable max-line-length */


%bg-icon__new--lighter-gray {
    background-image: iconNew( $color-lighter-gray );
}

%bg-icon__new--black {
    background-image: iconNew( $color-black );
}
