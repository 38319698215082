@use "sass:math";

@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

$icon-basket-aspect-ratio: math.div( 30, 26 );

/* stylelint-disable max-line-length */
@function iconBasket( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 15 13">' +
        '   <title>basket</title>' +
        '   <path d="M5.5,3a2,2,0,0,1,4,0h1.86A4,4,0,0,0,8.1.06,3,3,0,0,0,6.9.06,4,4,0,0,0,3.64,3Z" fill="#{ $color }" />' +
        '   <path d="M11.4,13H3.6A1.53,1.53,0,0,1,2,11.7L.52,4.89A1,1,0,0,1,.69,4a1.31,1.31,0,0,1,1.09-.41H13.22A1.31,1.31,0,0,1,14.31,4a1.05,1.05,0,0,1,.17.93L13,11.7A1.53,1.53,0,0,1,11.4,13Z" fill="#{ $color }" />' +
        '</svg>'
    );
}

@function iconBasketLight( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 15 13">' +
        '    <title>basket</title>' +
        '    <path d="M5.5,3a2,2,0,0,1,4,0h1.86A4,4,0,0,0,8.1.06,3,3,0,0,0,6.9.06,4,4,0,0,0,3.64,3Z" fill="#{ $color }" />' +
        '    <path d="M11.4,13H3.6A1.53,1.53,0,0,1,2,11.7L.52,4.89A1,1,0,0,1,.69,4a1.31,1.31,0,0,1,1.09-.41H13.22A1.31,1.31,0,0,1,14.31,4a1.05,1.05,0,0,1,.17.93L13,11.7A1.53,1.53,0,0,1,11.4,13ZM1.48,4.6l0,.08L3,11.49c.08.37.25.51.6.51H11.4c.35,0,.52-.14.6-.51l1.5-6.81a.45.45,0,0,0,0-.1s-.11,0-.3,0H1.78A.9.9,0,0,0,1.48,4.6Z" fill="#{ $color }" />' +
        '</svg>'
    );
}


/* stylelint-enable max-line-length */

%bg-icon__basket--black {
    background-image: iconBasket( $color-black );
}

%bg-icon__basket--medium-gray {
    background-image: iconBasket( $color-medium-gray );
}

%bg-icon__basket--light-gray {
    background-image: iconBasket( $color-light-gray );
}

%bg-icon__basket--lighter-gray {
    background-image: iconBasket( $color-lighter-gray );
}

%bg-icon__basket--super-lightest-gray {
    background-image: iconBasket( $color-super-lightest-gray );
}

%bg-icon__basket--main-blue {
    background-image: iconBasket( $color-main-blue );
}

%bg-icon__basket-light--black {
    background-image: iconBasketLight( $color-black );
}

%bg-icon__basket-light--main-blue {
    background-image: iconBasketLight( $color-main-blue );
}
