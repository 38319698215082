@import '../colors';

/**
 * We have a lot of elements (buttons, links, select boxes etc.) that get a colored border in a certain state (like `:focus` on text fields), which is not just
 * colored, but also a bit thicker than the default border of the element. Just changing `border-width` isn't a good idea, because it may affect the height of
 * the element (an `<input>` would grow when there's no `height` defined e.g.).
 * Use this mixin to add the additional border as background-image. It also takes care of the bottom border color.
 *
 * Typical usage:
 * ```
 * .some-textarea {
 *     border: 1px solid $color-something;
 *     &:focus {
 *         @include addVirtualBottomBorder( $color-blue-something );
 *     }
 * }
 * ```
 */
@mixin addVirtualBottomBorder( $color, $background-color: $color-white, $additional-border-width: 1px ) {
    border-bottom-color: $color;
    background-image: linear-gradient( to top, $color $additional-border-width, $background-color 0px );
    background-repeat: repeat-x;
    background-position: bottom;
}
