@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

/* stylelint-disable max-line-length */

@function iconCompany( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 40">' +
        '   <title>company</title>' +
        '   <g fill="#{ $color }">' +
        '      <rect x="39" y="20" width="9" height="20" />' +
        '      <path d="M1,14V40h9V14Zm7.2,9H2.8V17H8.2Z" />' +
        '      <path d="M11,3V40h7V26H30V40h8V3ZM22,23H16V17h6Zm0-10H16V7h6ZM33,23H27V17h6Zm0-10H27V7h6Z" />' +
        '   </g>' +
        '</svg>'
    );
}

/* stylelint-enable max-line-length */

%bg-icon__company--main-blue {
    background-image: iconCompany( $color-main-blue );
}

%bg-icon__company--lightest-gray {
    background-image: iconCompany( $color-lightest-gray );
}
