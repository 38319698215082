@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

/* stylelint-disable max-line-length */

@function iconStore( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 40">' +
        '   <title>store</title>' +
        '   <path d="M0,17V40H50V17ZM23.5,36H6.5V26h17Zm20,0h-17V26h17Z" fill="#{ $color }" />' +
        '</svg>'
    );
}

/* stylelint-enable max-line-length */

%bg-icon__store--main-blue {
    background-image: iconStore( $color-main-blue );
}

%bg-icon__store--lightest-gray {
    background-image: iconStore( $color-lightest-gray );
}
