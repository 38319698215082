@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

/* stylelint-disable max-line-length */
@function iconMagnifier( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 25 25">' +
        '   <title>I-13_magnifier_25</title>' +
        '   <path d="M24.7,23.1l-6.2-6h0a9.9,9.9,0,0,0,2.8-6.9A10.4,10.4,0,0,0,10.6,0,10.4,10.4,0,0,0,0,10.2,10.4,10.4,0,0,0,10.6,20.4a10.8,10.8,0,0,0,6.1-1.8h.1l6.2,6a1.2,1.2,0,0,0,1.6,0A1.1,1.1,0,0,0,24.7,23.1ZM1.8,10.2a8.6,8.6,0,0,1,8.8-8.5,8.6,8.6,0,0,1,8.8,8.5,8.6,8.6,0,0,1-8.8,8.5A8.6,8.6,0,0,1,1.8,10.2Z" ' +
        '           fill="#{ $color }"/>' +
        '</svg>'
    );
}
/* stylelint-enable max-line-length */

%bg-icon__magnifier--light-gray {
    background-image: iconMagnifier( $color-light-gray );
}

%bg-icon__magnifier--lighter-gray {
    background-image: iconMagnifier( $color-lighter-gray );
}

%bg-icon__magnifier--super-lightest-gray {
    background-image: iconMagnifier( $color-super-lightest-gray );
}

%bg-icon__magnifier--main-blue {
    background-image: iconMagnifier( $color-main-blue );
}
