@use "sass:math";

@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

$radiobutton-aspect-ratio: math.div( 26, 26 );

@function iconRadiobutton( $border-color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">' +
        '   <title>Radiobutton unchecked</title>' +
        '   <circle cx="13" cy="13" r="12.5" fill="none" stroke="#{ $border-color }" stroke-miterlimit="10"/>' +
        '</svg>'
    );
}

@function iconRadiobuttonChecked( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">' +
        '   <title>Radiobutton checked</title>' +
        '   <rect y="1" width="27" height="27" fill="none"/>' +
        '   <rect y="-1" width="27" height="27" fill="none"/>' +
        '   <g>' +
        '       <circle cx="13" cy="13" r="12.5" fill="none" stroke="#{ $color }" stroke-miterlimit="10"/>' +
        '       <circle cx="13" cy="13" r="6" fill="#{ $color }"/>' +
        '   </g>' +
        '</svg>'
    );
}

%bg-icon__radiobutton--default {
    background-image: iconRadiobutton( $color-light-gray );
}

%bg-icon__radiobutton--hover {
    background-image: iconRadiobutton( $color-main-blue );
}

%bg-icon__radiobutton--checked {
    background-image: iconRadiobuttonChecked( $color-main-blue );
}

%bg-icon__radiobutton--disabled {
    background-image: iconRadiobutton( $color-lighter-gray );
}

%bg-icon__radiobutton--disabled-checked {
    background-image: iconRadiobuttonChecked( $color-lighter-gray );
}
