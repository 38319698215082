/*
    # Background-Icons

    Include icons as background image.
    By default assumes that you want to put the icons into generated content blocks (`::before` and `::after`).

    Include icons like this:

        .foo {
            // generates ::before content block with size 50x50px and renders icon with background-size: contain.
            @include bg-icon-before( 'award-blue', ( width: 50px, height: 50px ) );
        }

        .foo {
            // generates ::after content block with size 50x50px and renders icon with background-size: contain.
            @include bg-icon-after( 'award-blue', ( width: 50px, height: 50px ) );
        }

        .foo {
            // icon container isn't considered as generated content block and icon will be sized to 23x42px (and placed
            // in the center of the container).
            @include bg-icon( 'award--blue', ( width: 23px, height: 42px ) );
        }

        .foo {
            // icon container isn't considered as generated content block and icon will be sized to 23x42px (and placed
            // on the left side of the container).
            @include bg-icon( 'award--blue', ( width: 23px, height: 42px ) );
            background-position: center left;
            padding-left: 23px;
        }

        .foo {
            // icon container isn't considered as generated content block and icon will take the size of the container.
            @include bg-icon( 'award--blue', cover, false );
            width: 23px;
            height: 42px;
        }


    If you want to add new icons, you (unfortunately) have to do three things:
        1. Create a file in the icons folder which…
        1.1 …defines a function that return the URL to your image. If this function returns a base64 encoded SVGs, optimize the SVGs with
            https://petercollingridge.appspot.com/svg-editor first)
        1.2 …implements a placeholder which uses the function and takes its return value as background-image
        2. Import this icon file in `_bg-icons.scss`.
        3. Add the icon to the big `if` in the ``_icon-by-id``-mixin.

    Why so complicated? To have the minimal actual output possible (using placeholders for sharing common CSS
    declarations, moving multiple used icons into one single selector etc.). Sass isn't very flexible when it comes to
    dynamically generating stuff like that…

    ## General stuff
*/

@import 'icons/arrow';
@import 'icons/arrow-light';
@import 'icons/basket';
@import 'icons/calendar';
@import 'icons/change';
@import 'icons/checkbox';
@import 'icons/checkmark';
@import 'icons/circled-checkmark';
@import 'icons/cloud';
@import 'icons/company';
@import 'icons/cross';
@import 'icons/denied';
@import 'icons/distribution-center';
@import 'icons/dividing-line';
@import 'icons/doc-excel';
@import 'icons/doc-zip';
@import 'icons/download';
@import 'icons/exclamation-mark';
@import 'icons/factory';
@import 'icons/hamburger';
@import 'icons/info';
@import 'icons/list';
@import 'icons/location';
@import 'icons/location-map';
@import 'icons/magnifier';
@import 'icons/message';
@import 'icons/new';
@import 'icons/pencil';
@import 'icons/plus';
@import 'icons/radiobutton';
@import 'icons/refresh';
@import 'icons/settings';
@import 'icons/slash';
@import 'icons/sort';
@import 'icons/store';
@import 'icons/table';
@import 'icons/to-do';
@import 'icons/trash-can';
@import 'icons/question-mark';

@mixin _icon-by-id( $iconId ) {

    // Sigh, SASS WHY U NO INTERPOLATING PLACEHOLDERS?!?

    // arrow-down
    // ----------------------------------------------------
    @if ( $iconId == 'arrow-down--black' ) {
        @extend %bg-icon__arrow-down--black;
    }
    @else if ( $iconId == 'arrow-down--dark-gray' ) {
        @extend %bg-icon__arrow-down--dark-gray;
    }
    @else if ( $iconId == 'arrow-down--medium-gray' ) {
        @extend %bg-icon__arrow-down--medium-gray;
    }
    @else if ( $iconId == 'arrow-down--light-gray' ) {
        @extend %bg-icon__arrow-down--light-gray;
    }
    @else if ( $iconId == 'arrow-down--lighter-gray' ) {
        @extend %bg-icon__arrow-down--lighter-gray;
    }
    @else if ( $iconId == 'arrow-down--super-lightest-gray' ) {
        @extend %bg-icon__arrow-down--super-lightest-gray;
    }
    @else if ( $iconId == 'arrow-down--main-blue' ) {
        @extend %bg-icon__arrow-down--main-blue;
    }
    @else if ( $iconId == 'arrow-down--white' ) {
        @extend %bg-icon__arrow-down--white;
    }

    // arrow-light-down
    // ----------------------------------------------------
    @else if ( $iconId == 'arrow-light-down--main-blue' ) {
        @extend %bg-icon__arrow-light-down--main-blue;
    }
    // arrow-light-down
    // ----------------------------------------------------
    @else if ( $iconId == 'arrow-light-down--dark-gray' ) {
        @extend %bg-icon__arrow-light-down--dark-gray;
    }


    // arrow-up
    // ----------------------------------------------------
    @else if ( $iconId == 'arrow-up--black' ) {
        @extend %bg-icon__arrow-up--black;
    }
    @else if ( $iconId == 'arrow-up--main-blue' ) {
        @extend %bg-icon__arrow-up--main-blue;
    }
    @else if ( $iconId == 'arrow-up--dark-gray' ) {
        @extend %bg-icon__arrow-up--dark-gray;
    }
    @else if ( $iconId == 'arrow-up--medium-gray' ) {
        @extend %bg-icon__arrow-up--medium-gray;
    }
    @else if ( $iconId == 'arrow-up--light-gray' ) {
        @extend %bg-icon__arrow-up--light-gray;
    }
    @else if ( $iconId == 'arrow-up--lighter-gray' ) {
        @extend %bg-icon__arrow-up--lighter-gray;
    }
    @else if ( $iconId == 'arrow-up--super-lightest-gray' ) {
        @extend %bg-icon__arrow-up--super-lightest-gray;
    }
    @else if ( $iconId == 'arrow-up--white' ) {
        @extend %bg-icon__arrow-up--white;
    }

    // arrow-light-up
    // ----------------------------------------------------
    @else if ( $iconId == 'arrow-light-up--main-blue' ) {
        @extend %bg-icon__arrow-light-up--main-blue;
    }
    @else if ( $iconId == 'arrow-light-up--dark-gray' ) {
        @extend %bg-icon__arrow-light-up--dark-gray;
    }

    // circled-arrow-down
    // ----------------------------------------------------
    @else if ( $iconId == 'circled-arrow-down--medium-gray' ) {
        @extend %bg-icon__circled-arrow-down--medium-gray;
    }
    @else if ( $iconId == 'circled-arrow-down--light-gray' ) {
        @extend %bg-icon__circled-arrow-down--light-gray;
    }

    // circled-arrow-up
    // ----------------------------------------------------
    @else if ( $iconId == 'circled-arrow-up--medium-gray' ) {
        @extend %bg-icon__circled-arrow-up--medium-gray;
    }
    @else if ( $iconId == 'circled-arrow-up--light-gray' ) {
        @extend %bg-icon__circled-arrow-up--light-gray;
    }

    // arrow-left
    // ----------------------------------------------------
    @else if ( $iconId == 'arrow-left--dark-gray' ) {
        @extend %bg-icon__arrow-left--dark-gray;
    }
    @else if ( $iconId == 'arrow-left--light-gray' ) {
        @extend %bg-icon__arrow-left--light-gray;
    }
    @else if ( $iconId == 'arrow-left--super-lightest-gray' ) {
        @extend %bg-icon__arrow-left--super-lightest-gray;
    }

    // arrow-right
    // ----------------------------------------------------
    @else if ( $iconId == 'arrow-right--black' ) {
        @extend %bg-icon__arrow-right--black;
    }
    @else if ( $iconId == 'arrow-right--light-gray' ) {
        @extend %bg-icon__arrow-right--light-gray;
    }
    @else if ( $iconId == 'arrow-right--super-lightest-gray' ) {
        @extend %bg-icon__arrow-right--super-lightest-gray;
    }


    // arrow-light-left
    // ----------------------------------------------------
    @else if ( $iconId == 'arrow-light-left--black' ) {
        @extend %bg-icon__arrow-light-left--black;
    }
    @else if ( $iconId == 'arrow-light-left--dark-gray' ) {
        @extend %bg-icon__arrow-light-left--dark-gray;
    }
    @else if ( $iconId == 'arrow-light-left--medium-gray' ) {
        @extend %bg-icon__arrow-light-left--medium-gray;
    }
    @else if ( $iconId == 'arrow-light-left--white' ) {
        @extend %bg-icon__arrow-light-left--white;
    }

    // arrow-light-right
    // ----------------------------------------------------
    @else if ( $iconId == 'arrow-light-right--black' ) {
        @extend %bg-icon__arrow-light-right--black;
    }
    @else if ( $iconId == 'arrow-light-right--light-gray' ) {
        @extend %bg-icon__arrow-light-right--light-gray;
    }
    @else if ( $iconId == 'arrow-light-right--dark-gray' ) {
        @extend %bg-icon__arrow-light-right--dark-gray;
    }

    // basket
    // ----------------------------------------------------
    @else if ( $iconId == 'basket--black' ) {
        @extend %bg-icon__basket--black;
    }
    @else if ( $iconId == 'basket--medium-gray' ) {
        @extend %bg-icon__basket--medium-gray;
    }
    @else if ( $iconId == 'basket--light-gray' ) {
        @extend %bg-icon__basket--light-gray;
    }
    @else if ( $iconId == 'basket--lighter-gray' ) {
        @extend %bg-icon__basket--lighter-gray;
    }
    @else if ( $iconId == 'basket--super-lightest-gray' ) {
        @extend %bg-icon__basket--super-lightest-gray;
    }
    @else if ( $iconId == 'basket--main-blue' ) {
        @extend %bg-icon__basket--main-blue;
    }


    // basket light
    // ----------------------------------------------------
    @else if ( $iconId == 'basket-light--main-blue' ) {
        @extend %bg-icon__basket-light--main-blue;
    }
    @else if ( $iconId == 'basket-light--black' ) {
        @extend %bg-icon__basket-light--black;
    }

    // calendar
    // ----------------------------------------------------
    @else if ( $iconId == 'calendar--dark-gray' ) {
        @extend %bg-icon__calendar--dark-gray;
    }
    @else if ( $iconId == 'calendar--light-gray' ) {
        @extend %bg-icon__calendar--light-gray;
    }
    @else if ( $iconId == 'calendar--main-blue' ) {
        @extend %bg-icon__calendar--main-blue;
    }

    // change-up
    // ----------------------------------------------------
    @else if ( $iconId == 'change-up--black' ) {
        @extend %bg-icon__change-up--black;
    }
    @else if ( $iconId == 'change-up--lighter-gray' ) {
        @extend %bg-icon__change-up--lighter-gray;
    }

    // change-down
    // ----------------------------------------------------
    @else if ( $iconId == 'change-down--black' ) {
        @extend %bg-icon__change-down--black;
    }
    @else if ( $iconId == 'change-down--lighter-gray' ) {
        @extend %bg-icon__change-down--lighter-gray;
    }

    // checkbox
    // ----------------------------------------------------
    @else if ( $iconId == 'checkbox--checked' ) {
        @extend %bg-icon__checkbox--checked;
    }
    @else if ( $iconId == 'checkbox--default' ) {
        @extend %bg-icon__checkbox--default;
    }
    @else if ( $iconId == 'checkbox--white' ) {
        @extend %bg-icon__checkbox--white;
    }
    @else if ( $iconId == 'checkbox--checked-white' ) {
        @extend %bg-icon__checkbox--checked-white;
    }
    @else if ( $iconId == 'checkbox--disabled' ) {
        @extend %bg-icon__checkbox--disabled;
    }
    @else if ( $iconId == 'checkbox--disabled-checked' ) {
        @extend %bg-icon__checkbox--disabled-checked;
    }
    @else if ( $iconId == 'checkbox--hover' ) {
        @extend %bg-icon__checkbox--hover;
    }
    @else if ( $iconId == 'checkbox--indeterminate' ) {
        @extend %bg-icon__checkbox--indeterminate;
    }
    @else if ( $iconId == 'checkbox--indeterminate-hover' ) {
        @extend %bg-icon__checkbox--indeterminate-hover;
    }
    @else if ( $iconId == 'checkbox--super-lightest-gray' ) {
        @extend %bg-icon__checkbox--super-lightest-gray;
    }

    // checkmark
    // ----------------------------------------------------
    @else if ( $iconId == 'checkmark--main-blue' ) {
        @extend %bg-icon__checkmark--main-blue;
    }
    @else if ( $iconId == 'checkmark--light-gray' ) {
        @extend %bg-icon__checkmark--light-gray;
    }
    @else if ( $iconId == 'checkmark--black' ) {
        @extend %bg-icon__checkmark--black;
    }
    @else if ( $iconId == 'checkmark--lighter-gray' ) {
        @extend %bg-icon__checkmark--lighter-gray;
    }

    // circled-checkmark
    // ----------------------------------------------------
    @else if ( $iconId == 'circled-checkmark--main-blue' ) {
        @extend %bg-icon__circled-checkmark--main-blue;
    }

    // cloud
    // ----------------------------------------------------
    @else if ( $iconId == 'cloud--lighter-gray' ) {
        @extend %bg-icon__cloud--lighter-gray;
    }
    @else if ( $iconId == 'cloud--black' ) {
        @extend %bg-icon__cloud--black;
    }

    // company
    // ----------------------------------------------------
    @else if ( $iconId == 'company--main-blue' ) {
        @extend %bg-icon__company--main-blue;
    }
    @else if ( $iconId == 'company--lightest-gray' ) {
        @extend %bg-icon__company--lightest-gray;
    }

    // Location
    // ----------------------------------------------------
    @else if ( $iconId == 'location--main-blue' ) {
        @extend %bg-icon__location--main-blue;
    }
    @else if ( $iconId == 'location--lightest-gray' ) {
        @extend %bg-icon__location--lightest-gray;
    }

    // Location Map
    // ----------------------------------------------------
    @else if ( $iconId == 'location-map--main-blue' ) {
        @extend %bg-icon__location-map--main-blue;
    }
    @else if ( $iconId == 'location-map--lightest-gray' ) {
        @extend %bg-icon__location-map--lightest-gray;
    }

    // Distribution Center
    // ----------------------------------------------------
    @else if ( $iconId == 'distribution-center--main-blue' ) {
        @extend %bg-icon__distribution-center--main-blue;
    }
    @else if ( $iconId == 'distribution-center--lightest-gray' ) {
        @extend %bg-icon__distribution-center--lightest-gray;
    }

    // Factory
    // ----------------------------------------------------
    @else if ( $iconId == 'factory--main-blue' ) {
        @extend %bg-icon__factory--main-blue;
    }
    @else if ( $iconId == 'factory--lightest-gray' ) {
        @extend %bg-icon__factory--lightest-gray;
    }

    // Store
    // ----------------------------------------------------
    @else if ( $iconId == 'store--main-blue' ) {
        @extend %bg-icon__store--main-blue;
    }
    @else if ( $iconId == 'store--lightest-gray' ) {
        @extend %bg-icon__store--lightest-gray;
    }


    // cross
    // ----------------------------------------------------
    @else if ( $iconId == 'cross--black' ) {
        @extend %bg-icon__cross--black;
    }
    @else if ( $iconId == 'cross--medium-gray' ) {
        @extend %bg-icon__cross--medium-gray;
    }
    @else if ( $iconId == 'cross--lighter-gray' ) {
        @extend %bg-icon__cross--lighter-gray;
    }
    @else if ( $iconId == 'cross--light-gray' ) {
        @extend %bg-icon__cross--light-gray;
    }
    @else if ( $iconId == 'cross--super-lightest-gray' ) {
        @extend %bg-icon__cross--super-lightest-gray;
    }
    @else if ( $iconId == 'cross--error-red' ) {
        @extend %bg-icon__cross--error-red;
    }

    // cross--circled
    // ----------------------------------------------------
    @else if ( $iconId == 'cross-circled--main-blue' ) {
        @extend %bg-icon__cross-circled--main-blue;
    }
    @else if ( $iconId == 'cross-circled--dark-blue' ) {
        @extend %bg-icon__cross-circled--dark-blue;
    }
    @else if ( $iconId == 'cross-circled--light-gray' ) {
        @extend %bg-icon__cross-circled--light-gray;
    }
    @else if ( $iconId == 'cross-circled--black' ) {
        @extend %bg-icon__cross-circled--black;
    }

    // denied
    // ----------------------------------------------------
    @else if ( $iconId == 'denied--lighter-gray' ) {
        @extend %bg-icon__denied--lighter-gray;
    }
    @else if ( $iconId == 'denied--black' ) {
        @extend %bg-icon__denied--black;
    }
    @else if ( $iconId == 'denied--error-red' ) {
        @extend %bg-icon__denied--error-red;
    }

    // dividing line
    // ----------------------------------------------------
    @else if ( $iconId == 'dividing-line--dark-gray' ) {
        @extend %bg-icon__dividing-line--dark-gray;
    }
    @else if ( $iconId == 'dividing-line--lighter-gray' ) {
        @extend %bg-icon__dividing-line--lighter-gray;
    }



    @else if ( $iconId == 'doc-excel--medium-gray' ) {
        @extend %bg-icon__doc-excel--medium-gray;
    }


    @else if ( $iconId == 'doc-zip--medium-gray' ) {
        @extend %bg-icon__doc-zip--medium-gray;
    }

    // download
    // ----------------------------------------------------
    @else if ( $iconId == 'download--dark-gray' ) {
        @extend %bg-icon__download--dark-gray;
    }

    // exclamation-mark
    // ----------------------------------------------------
    @else if ( $iconId == 'exclamation-mark--error-red' ) {
        @extend %bg-icon__exclamation-mark--error-red;
    }
    @else if ( $iconId == 'exclamation-mark--dark-gray' ) {
        @extend %bg-icon__exclamation-mark--dark-gray;
    }
    @else if ( $iconId == 'exclamation-mark--light-gray' ) {
        @extend %bg-icon__exclamation-mark--light-gray;
    }

    // hamburger
    // ----------------------------------------------------
    @else if ( $iconId == 'hamburger--lighter-gray' ) {
        @extend %bg-icon__hamburger--lighter-gray;
    }
    @else if ( $iconId == 'hamburger--super-lightest-gray' ) {
        @extend %bg-icon__hamburger--super-lightest-gray;
    }

    // Info
    // ----------------------------------------------------
    @else if ( $iconId == 'info--lighter-gray' ) {
        @extend %bg-icon__info--lighter-gray;
    } @else if ( $iconId == 'info--main-blue' ) {
        @extend %bg-icon__info--main-blue;
    }

    // list
    // ----------------------------------------------------
    @else if ( $iconId == 'list--main-blue' ) {
        @extend %bg-icon__list--main-blue;
    }
    @else if ( $iconId == 'list--light-gray' ) {
        @extend %bg-icon__list--light-gray;
    }
    @else if ( $iconId == 'list--super-lightest-gray' ) {
        @extend %bg-icon__list--super-lightest-gray;
    }

    // magnifier
    // ----------------------------------------------------
    @else if ( $iconId == 'magnifier--light-gray' ) {
        @extend %bg-icon__magnifier--light-gray;
    }
    @else if ( $iconId == 'magnifier--lighter-gray' ) {
        @extend %bg-icon__magnifier--lighter-gray;
    }
    @else if ( $iconId == 'magnifier--super-lightest-gray' ) {
        @extend %bg-icon__magnifier--super-lightest-gray;
    }
    @else if ( $iconId == 'magnifier--main-blue' ) {
        @extend %bg-icon__magnifier--main-blue;
    }

    // message
    // ----------------------------------------------------
    @else if ( $iconId == 'message--dark-gray' ) {
        @extend %bg-icon__message--dark-gray;
    }
    @else if ( $iconId == 'message--lighter-gray' ) {
        @extend %bg-icon__message--lighter-gray;
    }
    @else if ( $iconId == 'message--super-lightest-gray' ) {
        @extend %bg-icon__message--super-lightest-gray;
    }

    // new
    // ----------------------------------------------------
    @else if ( $iconId == 'new--lighter-gray' ) {
        @extend %bg-icon__new--lighter-gray;
    }
    @else if ( $iconId == 'new--black' ) {
        @extend %bg-icon__new--black;
    }

    // pencil
    // ----------------------------------------------------
    @else if ( $iconId == 'pencil--black' ) {
        @extend %bg-icon__pencil--black;
    }
    @else if ( $iconId == 'pencil--light-gray' ) {
        @extend %bg-icon__pencil--light-gray;
    }

    // plus
    // ----------------------------------------------------
    @else if ( $iconId == 'plus--light-gray' ) {
        @extend %bg-icon__plus--light-gray;
    }
    @else if ( $iconId == 'plus--lightest-gray' ) {
        @extend %bg-icon__plus--lightest-gray;
    }
    @else if ( $iconId == 'plus--main-blue' ) {
        @extend %bg-icon__plus--main-blue;
    }

    // question mark
    // ----------------------------------------------------
    @else if ( $iconId == 'question-mark--light-gray' ) {
        @extend %bg-icon__question-mark--light-gray;
    }
    @else if ( $iconId == 'question-mark--lighter-gray' ) {
        @extend %bg-icon__question-mark--lighter-gray;
    }
    @else if ( $iconId == 'question-mark--lightest-gray' ) {
        @extend %bg-icon__question-mark--lightest-gray;
    }
    @else if ( $iconId == 'question-mark--super-lightest-gray' ) {
        @extend %bg-icon__question-mark--super-lightest-gray;
    }
    @else if ( $iconId == 'question-mark--main-blue' ) {
        @extend %bg-icon__question-mark--main-blue;
    }

    // radiobutton
    // ----------------------------------------------------
    @else if ( $iconId == 'radiobutton--checked' ) {
        @extend %bg-icon__radiobutton--checked;
    }
    @else if ( $iconId == 'radiobutton--default' ) {
        @extend %bg-icon__radiobutton--default;
    }
    @else if ( $iconId == 'radiobutton--disabled' ) {
        @extend %bg-icon__radiobutton--disabled;
    }
    @else if ( $iconId == 'radiobutton--disabled-checked' ) {
        @extend %bg-icon__radiobutton--disabled-checked;
    }
    @else if ( $iconId == 'radiobutton--hover' ) {
        @extend %bg-icon__radiobutton--hover;
    }

    // refresh
    // ----------------------------------------------------
    @else if ( $iconId == 'refresh--light-gray' ) {
        @extend %bg-icon__refresh--light-gray;
    }
    @else if ( $iconId == 'refresh--black' ) {
        @extend %bg-icon__refresh--black;
    }

    // settings
    // ----------------------------------------------------
    @else if ( $iconId == 'settings--lighter-gray' ) {
        @extend %bg-icon__settings--lighter-gray;
    }
    @else if ( $iconId == 'settings--super-lightest-gray' ) {
        @extend %bg-icon__settings--super-lightest-gray;
    }

    // slash
    // ----------------------------------------------------
    @else if ( $iconId == 'slash--dark-gray' ) {
        @extend %bg-icon__slash--dark-gray;
    }
    @else if ( $iconId == 'slash--light-gray' ) {
        @extend %bg-icon__slash--light-gray;
    }

    // sort-down
    // ----------------------------------------------------
    @else if ( $iconId == 'sort-down--dark-gray' ) {
        @extend %bg-icon__sort-down--dark-gray;
    }
    @else if ( $iconId == 'sort-down--main-blue' ) {
        @extend %bg-icon__sort-down--main-blue;
    }
    @else if ( $iconId == 'sort-down--lighter-gray' ) {
        @extend %bg-icon__sort-down--lighter-gray;
    }

    // sort-up
    // ----------------------------------------------------
    @else if ( $iconId == 'sort-up--dark-gray' ) {
        @extend %bg-icon__sort-up--dark-gray;
    }
    @else if ( $iconId == 'sort-up--main-blue' ) {
        @extend %bg-icon__sort-up--main-blue;
    }
    @else if ( $iconId == 'sort-up--lighter-gray' ) {
        @extend %bg-icon__sort-up--lighter-gray;
    }

    // table
    // ----------------------------------------------------
    @else if ( $iconId == 'table--main-blue' ) {
        @extend %bg-icon__table--main-blue;
    }
    @else if ( $iconId == 'table--light-gray' ) {
        @extend %bg-icon__table--light-gray;
    }
    @else if ( $iconId == 'table--super-lightest-gray' ) {
        @extend %bg-icon__table--super-lightest-gray;
    }

    // to do
    // ----------------------------------------------------
    @else if ( $iconId == 'to-do--dark-gray' ) {
        @extend %bg-icon__to-do--dark-gray;
    }

    // trash can
    // ----------------------------------------------------
    @else if ( $iconId == 'trash-can--dark-gray' ) {
        @extend %bg-icon__trash-can--dark-gray;
    }

    @else {
        @error "Icon with ID '#{$iconId}' not known.";
    }
}




%bg-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
}

%bg-icon-generated-content {
    content: '';
    display: inline-block;
    vertical-align: middle;
    box-sizing: content-box; /* If there's padding defined, it's easier to set the width/height to the icon size and the
                                padding can be set extra without fiddling with the provided width and height for bg-icon(). */
}


@mixin _bg-icon-before-after( $iconId, $size ) {

    @extend %bg-icon;
    @extend %bg-icon-generated-content;

    background-size: contain;
    height: map-get( $size, 'height' );
    width: map-get( $size, 'width' );

    @include _icon-by-id( $iconId );
}



/**
    Generates ::before content block with given size and renders icon with background-size: contain.

    Params:
        - $iconId - Icon id.
        - $size - Size of the container, f.e. ( width: 50px, height: 50px ).
 */
@mixin bg-icon-before( $iconId, $size ) {

    &::before {
        @include _bg-icon-before-after( $iconId, $size );
    }
}

/**
    Generates ::before content block with given size and renders icon with background-size: contain.

    Params:
        - $iconId - icon id.
        - $size - Size of the container, f.e. ( width: 50px, height: 50px ).
 */
@mixin bg-icon-after( $iconId, $size ) {

    &::after {
        @include _bg-icon-before-after( $iconId, $size );
    }
}


/**
    Sets icon with given id as background with given background-size.

    Params:
        - $iconId - icon id.
        - $size - background size. Possible values: ( width: ..., height: ... ), contain, cover.
 */
@mixin bg-icon( $iconId, $size: null ) {

    @if ( $size == null ) {
        $size: ( width: 100%, height: 100% );
    }

    @extend %bg-icon;

    $bg-size-width: null;
    $bg-size-height: null;

    @if ( $size == contain OR $size == cover ) {
        background-size: $size;
    }
    else {
        @if ( type-of( $size ) == 'map' ) {
            @if ( type-of( map-get( $size, 'width' ) ) == 'number' OR map-get( $size, 'width' ) == auto) {
                $bg-size-width: map-get( $size, 'width' );
            }

            @if ( type-of( map-get( $size, 'height' ) ) == 'number' OR map-get( $size, 'height' ) == auto) {
                $bg-size-height: map-get( $size, 'height' );
            }

            @if (( type-of( $bg-size-width ) == 'number' OR $bg-size-width == auto )
            AND ( type-of( $bg-size-height ) == 'number' OR $bg-size-height == auto )) {
                // set width and height
                background-size: $bg-size-width $bg-size-height;
            }
        }
    }
    @include _icon-by-id( $iconId );
}
