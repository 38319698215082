@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';


@function iconCross( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">' +
        '    <title>I-01_Cross</title>' +
        '    <rect fill="#{ $color }" x="-3.2" y="9.1" width="26.4" height="1.86" transform="translate(-4.1 10) rotate(-45)"/>' +
        '    <rect fill="#{ $color }" x="9.1" y="-3.2" width="1.9" height="26.42" transform="translate(-4.1 10) rotate(-45)"/>' +
        '</svg>'
    );
}

@function iconCrossCircled( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">' +
        '   <title>I-17_Close</title>' +
        '   <circle fill="#{ $color }" cx="22" cy="22" r="22"/>' +
        '   <polygon points="30.7 14.9 29.1 13.3 22 20.4 14.9 13.3 13.3 14.9 20.4 22 13.3 29.1 14.9 30.7 22 23.6 29.1 30.7 30.7 29.1 23.6 22 30.7 14.9" ' +
        '           fill="#fff"/>' +
        '</svg>'
    );
}


// cross

%bg-icon__cross--black {
    background-image: iconCross( $color-black );
}

%bg-icon__cross--medium-gray {
    background-image: iconCross( $color-medium-gray );
}

%bg-icon__cross--light-gray {
    background-image: iconCross( $color-light-gray );
}

%bg-icon__cross--lighter-gray {
    background-image: iconCross( $color-lighter-gray );
}

%bg-icon__cross--super-lightest-gray {
    background-image: iconCross( $color-super-lightest-gray );
}

%bg-icon__cross--error-red {
    background-image: iconCross( $color-error-red );
}


// cross-circled

%bg-icon__cross-circled--main-blue {
    background-image: iconCrossCircled( $color-main-blue );
}

%bg-icon__cross-circled--dark-blue {
    background-image: iconCrossCircled( $color-dark-blue );
}

%bg-icon__cross-circled--light-gray {
    background-image: iconCrossCircled( $color-light-gray );
}

%bg-icon__cross-circled--black {
    background-image: iconCrossCircled( $color-black );
}
