@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

/* tslint:disable:max-line-length */
@function iconMessage( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 25 25">' +
        '   <title>I-15_message</title>' +
        '   <path d="M24,10.3c0-3.6-5.2-6.6-11.5-6.6S1,6.6,1,10.3s5.2,6.6,11.5,6.6h1.3l1,4.5c3.2-.8,3-5.2,3-5.2C21.5,15.1,24,12.8,24,10.3Z" ' +
        '           fill="none" stroke="#{ $color }" stroke-miterlimit="10"/>' +
        '   <g>' +
        '       <circle cx="8.3" cy="10.6" r="1" fill="#{ $color }"/>' +
        '       <circle cx="12.5" cy="10.6" r="1" fill="#{ $color }"/>' +
        '       <circle cx="16.6" cy="10.6" r="1" fill="#{ $color }"/>' +
        '   </g>' +
        '</svg>'
    );
}

/* tslint:enable:max-line-length */

%bg-icon__message--dark-gray {
    background-image: iconMessage( $color-dark-gray );
}

%bg-icon__message--lighter-gray {
    background-image: iconMessage( $color-lighter-gray );
}

%bg-icon__message--super-lightest-gray {
    background-image: iconMessage( $color-super-lightest-gray );
}
