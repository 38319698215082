@use "sass:math";

@import '../01_globals/lib/bg-icons/bg-icons';
@import '../01_globals/lib/bg-icons/icons/checkbox';

.radiobutton {
    $default-width: 25px;

    position: relative;

    display: inline-block;

    width: $default-width;
    height: math.div( $default-width, $radiobutton-aspect-ratio );

    &--radiobutton,
    &--fake-radiobutton {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &--radiobutton {
        opacity: 0;
        z-index: 1;

        &:checked + .radiobutton--fake-radiobutton {
            @include bg-icon( 'radiobutton--checked', cover );
        }

        &:focus + .radiobutton--fake-radiobutton,
        &:hover + .radiobutton--fake-radiobutton {
            @include bg-icon( 'radiobutton--hover', cover );
        }

        // dummy classes for styleguide- and demonstration-purposed
        @at-root {
            .radiobutton.is-radiobutton-focused > .radiobutton--fake-radiobutton,
            .radiobutton.is-radiobutton-hovered > .radiobutton--fake-radiobutton {
                @include bg-icon( 'radiobutton--hover', cover );
            }
        }

        &:disabled + .radiobutton--fake-radiobutton {
            @include bg-icon( 'radiobutton--disabled', cover );
        }

        &:disabled:checked + .radiobutton--fake-radiobutton {
            @include bg-icon( 'radiobutton--disabled-checked', cover );
        }
    }

    &--fake-radiobutton {
        @include bg-icon( 'radiobutton--default', cover );
    }
}
