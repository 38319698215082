@font-face {
    font-family: "AdihausDIN";
    font-style: normal;
    font-weight: bold;

    src: url( '/assets/fonts/adihaus-din/AdihausDIN-Bold.eot' );
    src:
        url( '/assets/fonts/adihaus-din/AdihausDIN-Bold.eot?#iefix' ) format( 'eot' ),
        url( '/assets/fonts/adihaus-din/AdihausDIN-Bold.woff' ) format( 'woff' )
}
@font-face {
    font-family: "AdihausDIN";
    font-style: normal;
    font-weight: normal;
    font-stretch: condensed;

    src: url( '/assets/fonts/adihaus-din/AdihausDIN-Cond.eot' );
    src:
        url( '/assets/fonts/adihaus-din/AdihausDIN-Cond.eot?#iefix' ) format( 'eot' ),
        url( '/assets/fonts/adihaus-din/AdihausDIN-Cond.woff' ) format( 'woff' )
}
@font-face {
    font-family: "AdihausDIN";
    font-style: normal;
    font-weight: bold;
    font-stretch: condensed;

    src: url( '/assets/fonts/adihaus-din/AdihausDIN-CondBold.eot' );
    src:
        url( '/assets/fonts/adihaus-din/AdihausDIN-CondBold.eot?#iefix' ) format( 'eot' ),
        url( '/assets/fonts/adihaus-din/AdihausDIN-CondBold.woff' ) format( 'woff' )
}
@font-face {
    font-family: "AdihausDIN";
    font-style: normal;
    font-weight: 500;
    font-stretch: condensed;

    src: url( '/assets/fonts/adihaus-din/AdihausDIN-CondMedium.eot' );
    src:
        url( '/assets/fonts/adihaus-din/AdihausDIN-CondMedium.eot?#iefix' ) format( 'eot' ),
        url( '/assets/fonts/adihaus-din/AdihausDIN-CondMedium.woff' ) format( 'woff' )
}
@font-face {
    font-family: "AdihausDIN";
    font-style: normal;
    font-weight: normal;

    src: url( '/assets/fonts/adihaus-din/AdihausDIN-Regular.eot' );
    src:
        url( '/assets/fonts/adihaus-din/AdihausDIN-Regular.eot?#iefix' ) format( 'eot' ),
        url( '/assets/fonts/adihaus-din/AdihausDIN-Regular.woff' ) format( 'woff' )
}
@font-face {
    font-family: "AdihausDIN";
    font-style: normal;
    font-weight: 500;

    src: url( '/assets/fonts/adihaus-din/AdihausDIN-Medium.eot' );
    src:
        url( '/assets/fonts/adihaus-din/AdihausDIN-Medium.eot?#iefix' ) format( 'eot' ),
        url( '/assets/fonts/adihaus-din/AdihausDIN-Medium.woff' ) format( 'woff' )
}
