@use "sass:math";

@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

$icon-calendar-aspect-ratio: math.div( 16, 15 );

/* stylelint-disable max-line-length */
@function iconCalendar( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15">' +
        '   <title>calendar</title>' +
        '   <rect fill="#{ $color }" x="4" y="8" width="1" height="1"/>' +
        '   <rect fill="#{ $color }" x="6" y="8" width="1" height="1"/>' +
        '   <rect fill="#{ $color }" x="8" y="8" width="1" height="1"/>' +
        '   <rect fill="#{ $color }" x="10" y="8" width="1" height="1"/>' +
        '   <rect fill="#{ $color }" x="4" y="10" width="1" height="1"/>' +
        '   <rect fill="#{ $color }" x="6" y="10" width="1" height="1"/>' +
        '   <rect fill="#{ $color }" x="8" y="10" width="1" height="1"/>' +
        '   <path fill="#{ $color }" d="M12.5,2h-.75V3.5a1.25,1.25,0,0,1-2.5,0V2H6.75V3.5a1.25,1.25,0,0,1-2.5,0V2H3.5A2.5,2.5,0,0,0,1,4.5v7A2.5,2.5,0,0,0,3.5,14h9A2.5,2.5,0,0,0,15,11.5v-7A2.5,2.5,0,0,0,12.5,2ZM14,11.5A1.5,1.5,0,0,1,12.5,13h-9A1.5,1.5,0,0,1,2,11.5V6.75H14Z"/>' +
        '   <line x1="5.5" y1="1.3" x2="5.5" y2="3" fill="none" stroke="#{ $color }" stroke-linecap="round" stroke-miterlimit="5"/>' +
        '   <line x1="10.5" y1="1.3" x2="10.5" y2="3" fill="none" stroke="#{ $color }" stroke-linecap="round" stroke-miterlimit="5"/>' +
        '</svg>'
    );
}
/* stylelint-enable max-line-length */

%bg-icon__calendar--main-blue {
    background-image: iconCalendar( $color-main-blue );
}

%bg-icon__calendar--light-gray {
    background-image: iconCalendar( $color-light-gray );
}

%bg-icon__calendar--dark-gray {
    background-image: iconCalendar( $color-dark-gray );
}
