@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';


/* stylelint-disable max-line-length */
@function iconInfo( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
        '   <path fill="#{ $color }" d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm.62,3a1.25,1.25,0,1,1-1.25,1.25A1.25,1.25,0,0,1,12.62,3ZM14,18.78q-1.59,2-2.95,2-1,0-1-1.32,0-.56.41-3.42L11,12.31l.14-.94.11-.75a5.81,5.81,0,0,0,.07-.8c0-.45-.13-.68-.38-.68q-1.06,0-2.27,2.32A.64.64,0,0,1,8.39,11q0-.83,1.53-2.23a4.28,4.28,0,0,1,2.44-1.4q1,0,1,1.41a11.09,11.09,0,0,1-.11,1.11l-.12.91L13,11.92l-.56,3.88-.14.87a15.82,15.82,0,0,0-.18,1.72q0,.58.34.58,1.14,0,2.34-2.54c.24.15.36.29.36.42A4.58,4.58,0,0,1,14,18.78Z"/>' +
        '</svg>'
    );
}
/* stylelint-enable max-line-length */

%bg-icon__info--lighter-gray {
    background-image: iconInfo( $color-lighter-gray );
}

%bg-icon__info--main-blue {
    background-image: iconInfo( $color-main-blue );
}
