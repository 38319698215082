@import '../01_globals/colors';
@import '../01_globals/forms';
@import '../01_globals/lib/bg-icons/bg-icons';
@import '../01_globals/lib/add-virtual-bottom-border';

// TODO: Modules shouldn't be included in base. For now it's ok, but long-term wise we should change that (probably when we move our base styles to styles.scss)
@import '../05_module/input';
@import '../05_module/checkbox';
@import '../05_module/keyword';
@import '../05_module/radiobutton';
@import '../05_module/supplementary-caption';


/*
 * this is (nearly) the same styling as in input (see input.scss) and TEMPORARY
 */
textarea {
    $background-color: $color-white;

    border: 1px solid $color-light-gray;

    color: $color-black;

    background-color: $background-color;

    padding: $form-input-vertical-padding $form-input-horizontal-padding;

    &:focus {
        border-color: $color-medium-gray;

        @include addVirtualBottomBorder( $color-main-blue );
    }

    &:disabled {
        color: $color-lighter-gray;
        border-color: $color-lighter-gray
    }


    &.ng-dirty.ng-valid {
        border-color: $color-medium-gray;
        @include addVirtualBottomBorder( $color-valid-green );
    }

    &:invalid,
    &.ng-invalid.ng-dirty {
        border-color: $color-medium-gray;
        @include addVirtualBottomBorder( $color-error-red );
    }
}

// hide clear button in internet explorer
::-ms-clear {
    display: none;
}
