@import '../01_globals/colors';
@import '../01_globals/font-stack';

.pika-single {
    font-family: $adidas-font-stack;

    .is-today .pika-button {
        color: $color-main-blue;
    }

    .is-selected .pika-button,
    .has-event .pika-button {
        color: $color-white;
        background: $color-main-blue;
        box-shadow: inset 0 1px 3px $color-main-blue;
    }

    .has-event .pika-button {
        background: $color-main-blue;
        box-shadow: inset 0 1px 3px $color-main-blue;
    }

    .is-disabled .pika-button,
    .is-inrange .pika-button {
        background: $color-lighter-gray;
    }

    .pika-button:hover,
    .pika-row.pick-whole-week:hover .pika-button {
        color: $color-white;
        background: $color-main-blue;
    }
}
