@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

$icon-arrow-left-width: 6px;

@function iconArrowUp( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="12.5" viewBox="0 0 20 12.5">' +
        '    <title>Arrow Up</title>' +
        '    <polygon points="2.8 12.5 10 5.3 17.2 12.5 20 9.9 10 0 0 10 2.8 12.5" fill="#{ $color }"/>' +
        '</svg>'
    );
}

@function circledIconArrowUp( $circle_color, $arrow_color: $color-white ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">' +
        '   <title>Circled Arrow Up</title>' +
        '   <g>' +
        '       <circle cx="22" cy="22" r="22" fill="#{ $circle_color }"/>' +
        '       <polygon points="36 25.6 24.1 13.8 24.1 13.8 22 11.7 22 11.7 22 11.7 19.9 13.8 19.9 13.8 8 25.6 10.1 27.7 22 15.9 33.9 27.7 36 25.6" ' +
        '           fill="#{ $arrow_color}"/>' +
        '   </g>' +
        '</svg>'
    );
}

@function iconArrowLightUp( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">' +
        '   <title>I-16-1_Arrow_up</title>' +
        '   <polygon points="8.6 1.6 0.8 9.3 2 10.5 9.8 2.8 17.6 10.5 18.8 9.3 11 1.6 9.8 0.5 8.6 1.6" fill="#{ $color }"/>' +
        '</svg>'
    )
}


@function iconArrowDown( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="12.5" viewBox="0 0 20 12.5">' +
        '   <title>Arrow Down</title>' +
        '   <polygon points="2.8 0 10 7.2 17.2 0 20 2.7 10 12.5 0 2.5 2.8 0" fill="#{ $color }"/>' +
        '</svg>'
    );
}

@function circledIconArrowDown( $circle_color, $arrow_color: $color-white ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">' +
        '   <title>Circled Arrow Down</title>' +
        '   <g>' +
        '       <circle cx="22" cy="22" r="22" fill="#{ $circle_color }"/>' +
        '       <polygon points="8 18.4 19.9 30.2 19.9 30.2 22 32.3 22 32.3 22 32.3 24.1 30.2 24.1 30.2 36 18.4 33.9 16.3 22 28.1 10.1 16.3 8 18.4"' +
        '           fill="#{ $arrow_color }"/>' +
        '   </g>' +
        '</svg>'
    );
}

@function iconArrowLightDown( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="11" viewBox="0 0 20 11">' +
        '   <title>I-16-1_Arrow_Down</title>' +
        '   <polygon points="11 8.8 18.8 1.1 17.6 0 9.8 7.6 2 0 0.8 1.1 8.6 8.8 9.8 10 11 8.8" fill="#{ $color }"/>' +
        '</svg>'
    )
}

@function iconArrowLeft( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="5.6" height="9" viewBox="0 0 5.6 9">' +
        '   <title>I-02-3_arrow_left</title>' +
        '   <polygon points="5.6 1.3 2.4 4.5 5.6 7.7 4.4 9 0 4.5 4.5 0 5.6 1.3" fill="#{ $color }"/>' +
        '</svg>'
    );
}

@function iconArrowRight( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="5.6" height="9" viewBox="0 0 5.6 9">' +
        '   <title>I-02-4_arrow_right</title>' +
        '   <polygon points="0 1.3 3.3 4.5 0 7.7 1.2 9 5.6 4.5 1.1 0 0 1.3" fill="#{ $color }"/>' +
        '</svg>'
    );
}


// arrow up

%bg-icon__arrow-up--black {
    background-image: iconArrowUp( $color-black );
}

%bg-icon__arrow-up--dark-gray {
    background-image: iconArrowUp( $color-dark-gray );
}

%bg-icon__arrow-up--medium-gray {
    background-image: iconArrowUp( $color-medium-gray );
}

%bg-icon__arrow-up--light-gray {
    background-image: iconArrowUp( $color-light-gray );
}

%bg-icon__arrow-up--lighter-gray {
    background-image: iconArrowUp( $color-lighter-gray );
}

%bg-icon__arrow-up--super-lightest-gray {
    background-image: iconArrowUp( $color-super-lightest-gray );
}

%bg-icon__arrow-up--white {
    background-image: iconArrowUp( $color-white );
}

%bg-icon__arrow-up--main-blue {
    background-image: iconArrowUp( $color-main-blue );
}


// arrow up circled

%bg-icon__circled-arrow-up--medium-gray {
    background-image: circledIconArrowUp( $color-medium-gray );
}

%bg-icon__circled-arrow-up--light-gray {
    background-image: circledIconArrowUp( $color-light-gray );
}

// arrow light up
%bg-icon__arrow-light-up--main-blue {
    background-image: iconArrowLightUp( $color-main-blue );
}

%bg-icon__arrow-light-up--dark-gray {
    background-image: iconArrowLightUp( $color-dark-gray );
}


// arrow-down

%bg-icon__arrow-down--black {
    background-image: iconArrowDown( $color-black );
}

%bg-icon__arrow-down--dark-gray {
    background-image: iconArrowDown( $color-dark-gray );
}

%bg-icon__arrow-down--medium-gray {
    background-image: iconArrowDown( $color-medium-gray );
}

%bg-icon__arrow-down--light-gray {
    background-image: iconArrowDown( $color-light-gray );
}

%bg-icon__arrow-down--lighter-gray {
    background-image: iconArrowDown( $color-lighter-gray );
}

%bg-icon__arrow-down--super-lightest-gray {
    background-image: iconArrowDown( $color-super-lightest-gray );
}

%bg-icon__arrow-down--white {
    background-image: iconArrowDown( $color-white );
}

%bg-icon__arrow-down--main-blue {
    background-image: iconArrowDown( $color-main-blue );
}


// arrow-down - circled

%bg-icon__circled-arrow-down--medium-gray {
    background-image: circledIconArrowDown( $color-medium-gray );
}

%bg-icon__circled-arrow-down--light-gray {
    background-image: circledIconArrowDown( $color-light-gray );
}

// arrow light down
%bg-icon__arrow-light-down--main-blue {
    background-image: iconArrowLightDown( $color-main-blue );
}

%bg-icon__arrow-light-down--dark-gray {
    background-image: iconArrowLightDown( $color-dark-gray );
}

// arrow left

%bg-icon__arrow-left--dark-gray {
    background-image: iconArrowLeft( $color-dark-gray );
}

%bg-icon__arrow-left--light-gray {
    background-image: iconArrowLeft( $color-light-gray );
}

%bg-icon__arrow-left--super-lightest-gray {
    background-image: iconArrowLeft( $color-super-lightest-gray );
}


// arrow right

%bg-icon__arrow-right--black {
    background-image: iconArrowRight( $color-black );
}

%bg-icon__arrow-right--light-gray {
    background-image: iconArrowRight( $color-light-gray );
}

%bg-icon__arrow-right--super-lightest-gray {
    background-image: iconArrowRight( $color-super-lightest-gray );
}
