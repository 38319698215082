@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

/* stylelint-disable max-line-length */

@function iconFactory( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 40">' +
        '   <title>factory</title>' +
        '   <g fill="#{ $color }">' +
        '      <path d="M0,18V40H50V18Zm34.25,5h3v3h-3ZM23.5,23h3v3h-3ZM12.75,23h3v3h-3ZM2,23H5v3H2ZM48,36H2V32H48Zm0-10H45V23h3Z"/>' +
        '      <polyline points="0 18 18 18 18 10"/>' +
        '      <polyline points="16 18 34 18 34 10"/>' +
        '      <polyline points="32 18 50 18 50 10"/>' +
        '   </g>' +
        '</svg>'
    );
}

/* stylelint-enable max-line-length */

%bg-icon__factory--main-blue {
    background-image: iconFactory( $color-main-blue );
}

%bg-icon__factory--lightest-gray {
    background-image: iconFactory( $color-lightest-gray );
}
