@import '../01_globals/colors';

a {
    color: $color-main-blue;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
