@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

/* stylelint-disable max-line-length */

@function iconLocationMap( $color ) {
    @return inline-svg(
        '<svg id="location-map" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 40">' +
        '   <title>location map</title>' +
        '   <g fill="#{ $color }">' +
        '      <path d="M38.67,28H33.31c-.66,1-1.36,2-2.1,3h5.35l.85,1.5H30.12l-.59.82L29,34h1l1.47,3H18.67l1.47-3H21l-.49-.68-.59-.82H16.39L14.66,31h4.13c-.74-1-1.45-2-2.1-3H11.33L4.5,40h41ZM16.81,37H10l3-5.29L15.63,34h2.65Zm15-3h6.41L40,37H33.32Z"/>' +
        '      <path d="M25,4a9.09,9.09,0,0,0-9,8.87c0,4.9,3.94,10.73,9,19.13,5.06-8.4,9-14.23,9-19.13A9.09,9.09,0,0,0,25,4Zm0,12.83a3.5,3.5,0,1,1,3.38-3.5A3.44,3.44,0,0,1,25,16.83Z"/>' +
        '   </g>' +
        '</svg>'
    );
}

/* stylelint-enable max-line-length */

%bg-icon__location-map--main-blue {
    background-image: iconLocationMap( $color-main-blue );
}

%bg-icon__location-map--lightest-gray {
    background-image: iconLocationMap( $color-lightest-gray );
}
