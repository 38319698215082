@use "sass:math";

@import '../01_globals/lib/bg-icons/bg-icons';
@import '../01_globals/lib/bg-icons/icons/checkbox';

.checkbox {
    $default-width: 1.665em;

    position: relative;

    display: inline-block;

    width: $default-width;
    height: math.div( $default-width, $icon-checkbox-aspect-ratio );

    &--checkbox,
    &--fake-checkbox {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &--checkbox {
        opacity: 0;
        z-index: 1;

        cursor: pointer;
        &:disabled,
        &.is-disabled {
            cursor: inherit;
        }

        &:checked + .checkbox--fake-checkbox,
        &.is-checked + .checkbox--fake-checkbox {
            @include bg-icon( 'checkbox--checked', cover );
        }

        &:focus + .checkbox--fake-checkbox,
        &:hover + .checkbox--fake-checkbox {
            @include bg-icon( 'checkbox--hover', cover );
        }

        // dummy classes for styleguide- and demonstration-purposed
        @at-root {
            .checkbox.is-checkbox-focused > .checkbox--fake-checkbox,
            .checkbox.is-checkbox-hovered > .checkbox--fake-checkbox {
                @include bg-icon( 'checkbox--hover', cover );
            }
        }

        &:disabled + .checkbox--fake-checkbox,
        &.is-disabled + .checkbox--fake-checkbox {
            @include bg-icon( 'checkbox--disabled', cover );
        }

        &:disabled:checked + .checkbox--fake-checkbox,
        &.is-disabled.is-checked + .checkbox--fake-checkbox {
            @include bg-icon( 'checkbox--disabled-checked', cover );
        }

        &:indeterminate + .checkbox--fake-checkbox,
        &.is-indeterminate + .checkbox--fake-checkbox {
            @include bg-icon( 'checkbox--indeterminate', cover );
        }

        &:indeterminate:hover + .checkbox--fake-checkbox,
        &:indeterminate:focus + .checkbox--fake-checkbox,
        &.is-indeterminate:hover + .checkbox--fake-checkbox,
        &.is-indeterminate:focus + .checkbox--fake-checkbox {
            @include bg-icon( 'checkbox--indeterminate-hover', cover );
        }
    }

    &--fake-checkbox {
        @include bg-icon( 'checkbox--default', cover );
    }
}
