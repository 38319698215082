@import '../01_globals/colors';
@import '../01_globals/forms';
@import '../01_globals/lib/add-virtual-bottom-border';
@import '../01_globals/lib/bg-icons/bg-icons';
@import '../01_globals/lib/bg-icons/icons/calendar';

%input {
    border: 1px solid $color-light-gray;
    padding: $form-input-vertical-padding $form-input-horizontal-padding;
    color: $color-black;
    background-color: $color-white;

    &:focus {
        border: 1px solid $color-medium-gray;

        border-bottom-color: $color-main-blue;
        @include addVirtualBottomBorder( $color-main-blue );
    }

    &:disabled {
        color: $color-lighter-gray;
        border-color: $color-lightest-gray;
        background-color: $color-white;
    }

    &.has-text-input-value:not(:disabled) {
        border-color: $color-medium-gray;
    }

    &.has-icon-space-left {
        padding-left: calc( 2 * #{$form-input-horizontal-padding} + 1em );
    }

    &.has-icon-space-right {
        padding-right: calc( 2 * #{$form-input-horizontal-padding} + 1em );
    }

    &.has-text-aligned-right {
        text-align: right;
    }

    &.ng-dirty.ng-valid {
        border-bottom-color: $color-valid-green;
        @include addVirtualBottomBorder( $color-valid-green );
    }

    &.ng-dirty.ng-invalid {
        border-bottom-color: $color-error-red;
        @include addVirtualBottomBorder( $color-error-red );
    }
}


%position_and_margin {
    position: absolute;
    margin: $form-input-vertical-padding $form-input-horizontal-padding;
}


%left-icon {
    @extend %position_and_margin;
    left: 0px;
    pointer-events: none;
}

@mixin icon( $iconId ) {
    @include bg-icon-before( $iconId, ( width: 1em, height: 1em ) );
}


.text-input {
    &--wrapper {
        display: inline-flex;

        position: relative;

        white-space: nowrap;
    }

    &--input {
        @extend %input;
    }

    &--toggle-datepicker-button {
        @extend %position_and_margin;
        left: 0px;

        border: 0;
        background-color: transparent;
        padding: 0; // override default button padding
        cursor: pointer;

        @extend %left-icon;

        @include bg-icon-before( 'calendar--light-gray', ( width: 1em * $icon-calendar-aspect-ratio, height: 1em ) );

        &:focus,
        &:hover,
        &.is-input-active {
            @include bg-icon-before( 'calendar--main-blue', ( width: 1em * $icon-calendar-aspect-ratio, height: 1em ) );
        }
    }

    &--clear-button {

        @extend %position_and_margin;

        left: 0px;

        border: 0;
        background-color: transparent;

        opacity: 0;
        visibility: hidden;

        padding: 0; // override default button padding

        cursor: pointer;

        @include icon( 'cross--medium-gray' );

        &.is-clear-button-right {
            right: 0;
            left: auto;
        }

        &.is-clear-button-visible {
            opacity: 1;
            visibility: visible;
        }

        &.is-clear-button-invalid {
            @include icon( 'cross--error-red' );
        }
    }

    &--magnifier-icon {
        @extend %left-icon;

        @include icon( 'magnifier--light-gray' );

        &.is-input-active {
            @include icon( 'magnifier--main-blue' );
        }
    }

    &--calendar-icon {
        @extend %left-icon;

        @include bg-icon-before( 'calendar--light-gray', ( width: 1em * $icon-calendar-aspect-ratio, height: 1em ) );

        &.is-input-active {
            @include bg-icon-before( 'calendar--main-blue', ( width: 1em * $icon-calendar-aspect-ratio, height: 1em ) );
        }
    }

    &--select-menu-icon {

        @extend %position_and_margin;

        pointer-events: none;
        right: 0;

        @include icon( 'arrow-down--dark-gray' );

        &.is-select-menu-active {
            @include icon( 'arrow-up--dark-gray' );
        }
    }

    &_without-clear-button {
        @extend %input;
    }
}


.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
    vertical-align: middle;

    &--input {
        display: none;

        &:checked + .switch--slider {
            background-color: $color-main-blue;
        }

        &:checked + .switch--slider::before {
            transform: translateX( 13px );
        }

        &:focus + .switch--slider {
            box-shadow: 0 0 1px $color-main-blue;
        }
    }

    &--slider:not(.is-disabled) {
        cursor: pointer;
    }

    &--slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-lighter-gray;
        border-radius: 17px;
        transition: .4s;

        &::before {
            position: absolute;
            content: '';
            height: 13px;
            width: 13px;
            left: 2px;
            bottom: 2px;
            background-color: $color-white;
            border-radius: 50%;
            transition: .4s;
        }
    }
}
