@use "sass:math";

@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

$icon-doc-excel-aspect-ratio: math.div( 17, 22 );

/* stylelint-disable max-line-length */
@function iconDocExcel( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 22">' +
        '    <title>Excel</title>' +
        '    <path d="M.5,1.5a1,1,0,0,1,1-1H12L16.5,5V20.5a1,1,0,0,1-1,1H1.5a1,1,0,0,1-1-1Z" fill="#FFF" /> ' +
        '    <path d="M17,4.79V20.5A1.5,1.5,0,0,1,15.5,22H1.5A1.5,1.5,0,0,1,0,20.5V1.5A1.5,1.5,0,0,1,1.5,0H12.21ZM15.5,21a.5.5,0,0,0,.5-.5V5.21L11.79,1H1.5a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5Z" fill="#{ $color }" /> ' +
        '    <path d="M11.5.5v3a2,2,0,0,0,2,2h3" fill="#FFF" />' +
        '    <path d="M12,.5H11v3A2.5,2.5,0,0,0,13.5,6h3V5h-3A1.5,1.5,0,0,1,12,3.5Z" fill="#{ $color }" />' +
        '    <rect x="3" y="8" width="5" height="3" fill="#{ $color }" />' +
        '    <rect x="3" y="12" width="5" height="3" fill="#{ $color }" />' +
        '    <rect x="3" y="16" width="5" height="3" fill="#{ $color }" />' +
        '    <rect x="9" y="8" width="5" height="3" fill="#{ $color }" />' +
        '    <rect x="9" y="12" width="5" height="3" fill="#{ $color }" />' +
        '    <rect x="9" y="16" width="5" height="3" fill="#{ $color }" />' +
        '</svg>'
    );
}
/* stylelint-enable max-line-length */

%bg-icon__doc-excel--medium-gray {
    background-image: iconDocExcel( $color-medium-gray );
}
