@use "sass:math";

@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

$icon-checkbox-aspect-ratio: math.div( 34, 28 );

@function iconCheckbox( $color, $is-indeterminate: false ) {
    $indeterminate-dash: if( $is-indeterminate, '<rect x="6" y="13.5" width="14" height="3" fill="#{ $color }" />', '' );
    $title: if( $is-indeterminate, 'Checkbox indeterminate', 'Checkbox unchecked' );

    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="34" height="28" viewBox="0 0 34 28">' +
        '    <title>#{ $title }</title>' +
        '    <rect y="1" width="27" height="27" fill="none"/>' +
        '    #{ $indeterminate-dash }' +
        '    <rect x="0.5" y="2.5" width="25" height="25" fill="none" stroke="#{ $color }" stroke-miterlimit="10"/>' +
        '</svg>'
    );
}

@function iconCheckboxChecked( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="34" height="28" viewBox="0 0 34 28">' +
        '    <title>Checkbox checked</title>' +
        '    <polygon points="6.2 10.4 13.6 17.9 30.7 0.7 33.6 3.5 13.6 23.3 3.3 13 6.2 10.4" fill="#{ $color }" />' +
        '    <rect y="1" width="27" height="27" fill="none"/>' +
        '    <polyline points="25.5 16 25.5 27.5 0.5 27.5 0.5 2.5 26 2.5" fill="none" stroke="#{ $color }" stroke-miterlimit="10"/>' +
        '</svg>'
    );
}


%bg-icon__checkbox--default {
    background-image: iconCheckbox( $color-light-gray );
}

%bg-icon__checkbox--hover {
    background-image: iconCheckbox( $color-main-blue );
}

%bg-icon__checkbox--checked {
    background-image: iconCheckboxChecked( $color-main-blue );
}

%bg-icon__checkbox--disabled {
    background-image: iconCheckbox( $color-lighter-gray );
}

%bg-icon__checkbox--white {
    background-image: iconCheckbox( $color-white );
}

%bg-icon__checkbox--checked-white {
    background-image: iconCheckboxChecked( $color-white );
}

%bg-icon__checkbox--disabled-checked {
    background-image: iconCheckboxChecked( $color-lighter-gray );
}

%bg-icon__checkbox--indeterminate {
    background-image: iconCheckbox( $color-light-gray, true );
}

%bg-icon__checkbox--indeterminate-hover {
    background-image: iconCheckbox( $color-main-blue, true );
}

// should we move this icon somewhere else? Used on secondary-toolbar-button.
%bg-icon__checkbox--super-lightest-gray {
    background-image: iconCheckboxChecked( $color-super-lightest-gray );
}
