@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

/* stylelint-disable max-line-length */
@function iconSettings( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 25 25" >' +
        '<title>I-11_settings</title>' +
        '   <path d="M24.5,14.1V10.9c-1.7-.6-2.7-.8-3.2-2h0c-.5-1.3.1-2.1.8-3.7L19.8,2.9c-1.6.7-2.4,1.4-3.7.8h0c-1.3-.5-1.4-1.6-2-3.2H10.9c-.6,1.6-.7,2.7-2,3.2h0c-1.3.5-2.1-.1-3.7-.8L2.9,5.2c.7,1.6,1.4,2.4.8,3.7s-1.6,1.4-3.2,2v3.2c1.6.6,2.7.7,3.2,2s-.1,2.2-.8,3.7l2.3,2.3c1.6-.7,2.4-1.4,3.7-.8h0c1.3.5,1.4,1.6,2,3.2h3.2c.6-1.6.8-2.7,2-3.2h0c1.3-.5,2.1.1,3.7.9l2.3-2.3c-.7-1.6-1.4-2.4-.8-3.7S22.9,14.7,24.5,14.1Zm-12,3.2a4.8,4.8,0,1,1,4.8-4.8A4.8,4.8,0,0,1,12.5,17.3Z" ' +
        '           fill="none" stroke="#{ $color }" stroke-miterlimit="10"/>' +
        '</svg>'
    );
}
/* stylelint-enable max-line-length */

%bg-icon__settings--lighter-gray {
    background-image: iconSettings( $color-lighter-gray );
}

%bg-icon__settings--super-lightest-gray {
    background-image: iconSettings( $color-super-lightest-gray );
}
