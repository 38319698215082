// Base styles for all <abbr>-tags.

abbr[title] {
    cursor: help;

    // normalize between Firefox (which uses `text-decoration: underline dotted`) and other browsers:
    text-decoration: none;
    border-bottom: 1px dashed;

    &.no-cursor-change {
        cursor: inherit;
    }
}
