@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

/* stylelint-disable max-line-length */

@function iconLocation( $color ) {
    @return inline-svg(
        '<svg id="location" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
        '    <title>location</title>' +
        '    <g fill="#{ $color }">' +
        '        <path d="M12,0C7.8,0,4,3.4,4,7.6S7.5,16.8,12,24c4.5-7.2,8-12.2,8-16.4S16.2,0,12,0Zm0,11a3,3,0,1,1,3-3A3,3,0,0,1,12,11Z"/>' +
        '    </g>' +
        '</svg>'
    );
}

/* stylelint-enable max-line-length */

%bg-icon__location--main-blue {
    background-image: iconLocation( $color-main-blue );
}

%bg-icon__location--lightest-gray {
    background-image: iconLocation( $color-lightest-gray );
}
