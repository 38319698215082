@import '../01_globals/colors';
@import '../01_globals/lib/keyword';

.keyword {
    @include keyword();
}

.keyword_bold {
    @include keyword( 'bold' );
}
