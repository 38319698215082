@import '../colors';

$keyword-font-size: 1em;
$keyword-top-padding: 0.4em;
$keyword-left-padding: 1em;
$keyword-right-padding: $keyword-left-padding;
$keyword-bottom-padding: $keyword-top-padding;
$keyword-border-width: 1px;
$keyword-bottom-margin: 0.5em;

@mixin keyword( $theme: 'default', $componentDivider: '--' ) {
    $keyword-close-button-left-margin: $keyword-top-padding;
    $keyword-close-button-width-height: 0.75em; /* this value is an approximation; it should go from the text-top to the baseline */

    &#{$componentDivider}with-clear-button {
        $font-color: if( $theme == 'bold', $color-white, $color-black );

        color: $font-color;
        /* stylelint-disable declaration-no-important */
        padding-right: $keyword-right-padding + $keyword-close-button-width-height + $keyword-close-button-left-margin !important;

        transition-duration: 0.2s !important;
        transition-timing-function: ease-in !important;
        /* stylelint-enable*/
    }

    $border-color: if( $theme == 'bold', $color-dark-gray, $color-light-gray );
    $font-color: if( $theme == 'bold', $color-lightest-gray, $color-medium-gray );
    $background-color: if( $theme == 'bold', $color-status-anthracite, transparent );

    position: relative;

    display: inline-block;
    text-align: center;

    padding: $keyword-top-padding $keyword-right-padding $keyword-bottom-padding $keyword-left-padding;
    margin-right: 0.5em;
    margin-bottom: $keyword-bottom-margin;

    background-color: $background-color;
    color: $font-color;

    border: $keyword-border-width solid $border-color;
    border-radius: 1.2em;

    font-size: $keyword-font-size;
    font-weight: normal;
    line-height: 1em;

    min-width: 4.2em;

    transition-duration: 0.2s;
    transition-timing-function: ease-in;
    transition-property: color, padding-right;

    &#{$componentDivider}close-button {
        position: absolute;
        top: $keyword-top-padding;
        right: $keyword-right-padding;

        width: $keyword-close-button-width-height;
        height: $keyword-close-button-width-height;
        margin-left: $keyword-close-button-left-margin;

        padding: 0;
        border: 0;

        background-color: transparent;

        vertical-align: baseline; // this is ignored when `position: absolute`, but if we sometime switch back to `position: static` this is the right value.
        cursor: pointer;

        @if ( $theme == 'bold' ) {
            @include bg-icon( 'cross--lighter-gray', cover );

            &:hover {
                @include bg-icon( 'cross--light-gray', cover );
            }
        }
        @else {
            @include bg-icon( 'cross--black', cover );

            &:hover {
                @include bg-icon( 'cross--medium-gray', cover );
            }
        }
    }
}
