@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

/* stylelint-disable max-line-length */

@function iconDistributionCenter( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 40">' +
        '   <title>distribution center</title>' +
        '   <g fill="#{ $color }">' +
        '      <polygon points="0 10 0 40 5.5 40 5.5 16 44.5 16 44.5 40 50 40 50 10 0 10"/>' +
        '      <rect x="9.5" y="19" width="31" height="1"/>' +
        '      <rect x="9.5" y="21" width="31" height="1"/>' +
        '      <rect x="9.5" y="23" width="31" height="1"/>' +
        '      <rect x="10" y="35" width="9" height="5"/>' +
        '      <rect x="20" y="35" width="9" height="5"/>' +
        '      <rect x="30" y="35" width="9" height="5"/>' +
        '      <rect x="25" y="29" width="9" height="5"/>' +
        '      <rect x="15" y="29" width="9" height="5"/>' +
        '   </g>' +
        '</svg>'
    );
}

/* stylelint-enable max-line-length */

%bg-icon__distribution-center--main-blue {
    background-image: iconDistributionCenter( $color-main-blue );
}

%bg-icon__distribution-center--lightest-gray {
    background-image: iconDistributionCenter( $color-lightest-gray );
}
