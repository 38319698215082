@use "sass:math";

@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

$icon-doc-zip-aspect-ratio: math.div( 17, 22 );

/* stylelint-disable max-line-length */
@function iconDocZip( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 22">' +
        '   <g>' +
        '       <g>' +
        '           <path d="M1.44,21.5H15.56a.94.94,0,0,0,.94-.94V5L12,.5H1.44a.94.94,0,0,0-.94.94V20.56A.94.94,0,0,0,1.44,21.5Z" fill="#fff"/>' +
        '           <path d="M15.55,22H1.45A1.45,1.45,0,0,1,0,20.55V1.45A1.45,1.45,0,0,1,1.45,0H12.21L17,4.79V20.55A1.45,1.45,0,0,1,15.55,22ZM1.45,1A.44.44,0,0,0,1,1.45v19.1a.44.44,0,0,0,.44.44h14.1a.44.44,0,0,0,.44-.44V5.21L11.79,1Z" fill="#{ $color }"/>' +
        '       </g>' +
        '       <g>' +
        '           <path d="M16.5,5.5h-3a2,2,0,0,1-2-2V.5" fill="#fff"/>' +
        '           <path d="M16.5,6h-3A2.5,2.5,0,0,1,11,3.5V.5h1v3A1.5,1.5,0,0,0,13.5,5h3Z" fill="#{ $color }"/>' +
        '       </g>' +
        '       <path d="M7,12.48,4.78,16.35a2.5,2.5,0,1,0,4.44,0Z" fill="#{ $color }" stroke="#{ $color }"/>' +
        '       <circle cx="7" cy="17.5" r="1.5" fill="#fff" stroke="#{ $color }"/>' +
        '       <path d="M7,15.5a.5.5,0,0,1-.5-.5v-.75a.5.5,0,0,1,1,0V15A.5.5,0,0,1,7,15.5Zm0-2.65a.5.5,0,0,1-.5-.5V11.1a.5.5,0,0,1,1,0v1.25A.5.5,0,0,1,7,12.85ZM7,9.7a.5.5,0,0,1-.5-.5V7.95a.5.5,0,0,1,1,0V9.2A.5.5,0,0,1,7,9.7ZM6.75,6.58a.5.5,0,0,1-.48-.36c-.1-.33-.22-.74-.36-1.17a.5.5,0,0,1,.95-.32c.15.45.28.87.38,1.22a.5.5,0,0,1-.34.62Zm-1.06-3a.5.5,0,0,1-.46-.29,9.83,9.83,0,0,0-.55-1.05.5.5,0,0,1,.85-.52A10.73,10.73,0,0,1,6.15,2.9a.5.5,0,0,1-.45.71Z" fill="#{ $color }"/>' +
        '       <path d="M7,15.5a.5.5,0,0,1-.5-.5v-.75a.5.5,0,0,1,1,0V15A.5.5,0,0,1,7,15.5Zm0-2.65a.5.5,0,0,1-.5-.5V11.1a.5.5,0,0,1,1,0v1.25A.5.5,0,0,1,7,12.85ZM7,9.7a.5.5,0,0,1-.5-.5V7.95a.5.5,0,0,1,1,0V9.2A.5.5,0,0,1,7,9.7Zm.25-3.12-.14,0a.5.5,0,0,1-.34-.62c.1-.35.23-.77.38-1.22A.5.5,0,0,1,8.09,5c-.15.43-.27.84-.36,1.17A.5.5,0,0,1,7.25,6.58Zm1.06-3a.5.5,0,0,1-.45-.71,10.72,10.72,0,0,1,.61-1.16.5.5,0,1,1,.85.52,9.82,9.82,0,0,0-.55,1.05A.5.5,0,0,1,8.31,3.61Z" fill="#{ $color }"/>' +
        '   </g>' +
        '</svg>'
    );
}
/* stylelint-enable max-line-length */

%bg-icon__doc-zip--medium-gray {
    background-image: iconDocZip( $color-medium-gray );
}
