/* You can add global styles to this file, and also import other style files */

// needed by Angular Material CDK (cdk-*).
@import '@angular/cdk/overlay-prebuilt.css';

@import 'app/shared/scss/01_globals/font-stack';
@import 'app/shared/scss/03_base/base';
@import 'app/shared/scss/05_module/pikaday';

html,
body {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;

    margin: 0;
    padding: 0;

    // actually this should be done in base.scss, but we then we have this weird Chrome bug which doesn't render copy
    // at all, when the @font-face definition comes up multiple times (because every Angular component should reference
    // base.scss).
    font-family: $adidas-font-stack;
    font-size: $base-font-size;

    // important for main-menu animation
    overflow: hidden;


    // TODO: RSP-17224 - took from normalize, delete after normalize is in use again
    button,
    input,
    optgroup,
    select,
    textarea {
        font: inherit;
        margin: 0;
    }

    button {
        overflow: visible;
    }
    // -----------------------------------------------------------------------------
}

// after wijmo was removed, chrome displayed outline for focused inputs. possible TODO @PS
*:focus {
    outline: none;
}
