// Box-size border-box all the things!

*, *::before, *::after {
    box-sizing: border-box;
}


//:host {
//    box-sizing: border-box;
//}
//:host *, :host *::before, :host *::after {
//    box-sizing: inherit;
//}
