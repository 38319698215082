/**
 * Colors
 *
 * # Description
 * Global SASS variables to be used for colors.
 *
 */


/**
 * ### colorful colors
 * ToDo: Remove once obsolete components are clarified!
 */
$color-adidas-green: #A8B400;
$color-adidas-green-p70: #C2C94D; // TODO: This is probably not 100% correct, because it was just measured with Digital Color Meter on styleguide's PDF.

/**
 * ### primary colors
 */
$color-main-blue: #0286CD;
$color-dark-blue: #02578E;
$color-darker-blue: #003C71;
$color-black: black;
$color-white: white;

/**
 * ### secondary colors
 */
$color-dark-gray: #363738;
$color-medium-gray: #616363;
$color-light-gray: #9A9B9B;
$color-lighter-gray: #C8CBCC;
$color-lightest-gray: #EBEBEB;
$color-super-lightest-gray: #F9F9F9;
$color-error-red: #DE6363;
$color-valid-green: #3DBF0E;
$color-warning-yellow: #FFC92B;

/**
 * ### tertiary colors
 */
$color-status-red: #E35339;
$color-status-yellow: #FFC92B;
$color-status-green: #7BBA87;
$color-status-sky-blue: #7ECEFD;
$color-status-cherry-blossom: #FFEDE3;
$color-status-violet: #DAADFF;
$color-status-gray: #ABAEBA;
$color-status-anthracite: #3E454C;
$color-status-dark-blue: #004f7c;
$color-status-purple: #76689a;
$color-status-orange: #da6e00;
$color-status-burgundy: #59191f;



/**
 * ### status colors
 *
 * This is a map, because it's recommended to use these values with something like `@each $status in ( map-keys( $color-for-status ) ) {}`
 */
$color-for-status: (
    active: (
        background-color: $color-status-green,
        text-color:       $color-white,
    ),
    activeinrollout: (
        background-color: $color-status-green,
        text-color:       $color-white,
    ),
    availabletoforecastandorder: (
        background-color: $color-status-green,
        text-color:       $color-white,
    ),
    availabletoorder: (
        background-color: $color-status-green,
        text-color:       $color-white,
    ),
    activeinprerollout: (
        background-color: $color-status-dark-blue,
        text-color:       $color-white,
    ),
    availabletoforecast: (
        background-color: $color-status-dark-blue,
        text-color:       $color-white,
    ),
    activeondemand: (
        background-color: $color-status-orange,
        text-color:       $color-black,
    ),
    activedelivery: (
        background-color: $color-status-sky-blue, // TODO: Green?
        text-color:       $color-black,
    ),
    canbedeliveredtocountry: (
        background-color: $color-status-yellow,
        text-color:       $color-black,
    ),
    canbedeliveredtosupplychainzone: (
        background-color: $color-status-green,
        text-color:       $color-white,
    ),
    cannotbedeliveredtocountry: (
        background-color: $color-status-red,
        text-color:       $color-white,
    ),
    creating: (
        background-color: $color-status-sky-blue,
        text-color:       $color-black,
    ),
    development: (
        background-color: $color-status-sky-blue,
        text-color:       $color-black,
    ),
    discontinue: (
        background-color: $color-status-yellow,
        text-color:       $color-black,
    ),
    expired: (
        background-color: $color-status-red,
        text-color:       $color-white,
    ),
    unavailable: (
        background-color: $color-status-burgundy,
        text-color:       $color-white,
    ),
    inactive: (
        background-color: $color-status-red,
        text-color:       $color-white,
    ),
    inprogress: (
        background-color: $color-status-yellow,
        text-color:       $color-black,
    ),
    registered: (
        background-color: $color-status-sky-blue,
        text-color:       $color-black,
    ),
    nodelivery: (
        background-color: $color-lighter-gray,
        text-color:       $color-black,
    ),
    planned: (
        background-color: $color-status-red,
        text-color:       $color-white,
    ),
    published: (
        background-color: $color-status-sky-blue,
        text-color:       $color-black,
    ),
    ready: (
        background-color: $color-status-green,
        text-color:       $color-white,
    ),
    unpublished: (
        background-color: $color-status-purple,
        text-color:       $color-white,
    )
);

/**
 * ### other colors
 */
$color-note: #F7F1DF;
