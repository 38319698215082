@import '../../../../01_globals/colors';
@import '@nureg/sass-inline-svg/index';

/* stylelint-disable max-line-length */

@function iconCloud( $color ) {
    @return inline-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
        '   <title>Cloud</title>' +
        '   <g fill="#{ $color }">' +
        '       <path d="M15.834,20.675a4.227,4.227,0,0,1-3.23-1.6c-1.239,1.674-2.523,1.652-3.223,1.464A3.508,3.508,0,0,1,7.2,18.574a4.052,4.052,0,0,1-4.058-.5,2.471,2.471,0,0,1-.819-1.962A2.741,2.741,0,0,1,.069,13.958a2.906,2.906,0,0,1,1.094-2.972,2.96,2.96,0,0,1,.363-3.677,3.306,3.306,0,0,1,3.234-.987,2.011,2.011,0,0,1,.657-1.2,5.1,5.1,0,0,1,4.59-.746,2.469,2.469,0,0,1,.959.576A3.878,3.878,0,0,1,15.313,3.5a2.939,2.939,0,0,1,2.21,2.259,3.645,3.645,0,0,1,3.922.7,2.692,2.692,0,0,1,.432,2.774,2.454,2.454,0,0,1,2.105,2.156A4.1,4.1,0,0,1,22.606,14.7a2.957,2.957,0,0,1-.861,3.345,3.735,3.735,0,0,1-3.192.562,2.713,2.713,0,0,1-2.575,2.064C15.93,20.674,15.882,20.675,15.834,20.675Zm-3.026-3.64.277,1.034a3.005,3.005,0,0,0,2.854,1.713c.707-.03,1.6-.38,1.867-1.869l.115-.653.561.353a2.76,2.76,0,0,0,2.715-.27,2.362,2.362,0,0,0,.46-2.713l-.137-.414.412-.145A3.057,3.057,0,0,0,23.1,11.482c-.094-.84-.738-1.335-1.915-1.472l-.662-.077.325-.583a1.941,1.941,0,0,0-.064-2.3c-.537-.6-1.75-.981-3.537-.242l-.75.31.141-.8c.171-.976-.669-1.69-1.579-1.965a2.958,2.958,0,0,0-3.684,1.7l-.612,1.4-.237-1.507c-.06-.385-.473-.606-.809-.723a4.228,4.228,0,0,0-3.693.56A1.083,1.083,0,0,0,5.675,6.93l.3,1.149-.979-.67a2.238,2.238,0,0,0-2.814.508,2.113,2.113,0,0,0-.03,2.929l.344.436-.5.241A1.98,1.98,0,0,0,.94,13.769a1.87,1.87,0,0,0,1.909,1.507l.58.014-.163.557a1.549,1.549,0,0,0,.476,1.574c.756.694,2.073.75,3.615.153l.607-.236v.651a2.461,2.461,0,0,0,1.65,1.685c1.181.321,2.1-.756,2.656-1.715Z"/>' +
        '   </g>' +
        '</svg>'
    );
}

/* stylelint-enable max-line-length */


%bg-icon__cloud--lighter-gray {
    background-image: iconCloud( $color-lighter-gray );
}

%bg-icon__cloud--black {
    background-image: iconCloud( $color-black );
}
